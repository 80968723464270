.cta__container {
  position: fixed;
  background: var(--mad-red);
  /* background: var(--knight-grey); */
  width: 100%;
}

.vid__contatiner {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.5;
  mix-blend-mode: overlay;
}

.cta__content {
  margin: 3rem 3rem 3rem 6rem;
}

.logo__container {
  position:absolute;
  top: 4rem;
  left: 6rem;
}

.logo__container img {
  width: 7rem;
  height: auto;
}

.logo__container img:hover{
  fill: var(--mad-red);
}

.heading {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.heading h1 span {
  font-family: var(--ffh);
  color: #fff;
  background: rgba(255, 255, 255, 0.4);
  /* opacity: 0.4; */
  font-size: 5.5rem;
  font-weight: 400;
  line-height: 5.5rem;
  /* margin-right: 3rem; */
  padding: 0.75rem;
}

.cta__comp {
  position: absolute;
  bottom: 5rem;
  /* transform: translateY(50%); */
}

/* ==================== MEDIA QUERIES (MEDIUM SIZE DEVICES) ==================== */
@media screen and (max-width: 1024px) {
  .cta__container {
    position: relative;
    width: 100vw;
  }

  .logo__container {
    top: 2rem;
    left: 4rem;
  }

  .cta__footer {
    display: none;
    opacity: 0;
    visibility: hidden;
  }
}

/* ==================== MEDIA QUERIES (SMALL SIZE DEVICES) ==================== */
@media screen and (max-width: 666px) {
  .cta__content {
    margin: 2rem;
  }

  .logo__container {
    top: 2rem;
    left: 2rem;
  }

  .logo__container img {
    width: 5rem;
    height: auto;
  }

  .heading {
    top: 45%;
  }

  .heading h1 span {
    font-size: 3.5rem;
    line-height: 4.5rem;
    padding: 0.5rem;
  }

  .cta__comp {
    bottom: 5rem;
  }
}

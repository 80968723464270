.footer__cta {
  display: flex;
  flex-direction: column;
  column-gap: 1rem;
}

.footer__cta a {
  transition: var(--transition);
}

.footer__cta a:hover {
  color: var(--mad-red);
}

.footer__cta h4 {
  opacity: 1;
  text-align: left;
  margin-right: 0;
  margin-top: 0.5rem;
  text-align: right;
  font-style: normal;
  color: var(--knight-grey);
}

/* ==================== MEDIA QUERIES (MEDIUM SIZE DEVICES) ==================== */
@media screen and (max-width: 1024px) {
  .footer__cta {
    flex-direction: row;
  }

  .footer__cta h4 {
    /* margin-top: 1rem; */
    text-align: left;
  }

  .cta__footer {
    opacity: 1;
    visibility: visible;
  }
}

/* ==================== MEDIA QUERIES (SMALL SIZE DEVICES) ==================== */
@media screen and (max-width: 666px) {
  .footer__cta {
    flex-direction: column;
  }

  .footer__cta a {
    font-size: 2.25rem;
  }

  .footer__cta h4 {
    /* margin-top: -0.5rem; */
  }

}
.hero__container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* gap: 2rem; */
}

.promo__content {
  top: 0;
  left: 0;
  background: var(--mad-red);
  overflow: auto;
}

.service__content {
  z-index: 0;
  /* position: inherit; */
  margin: 3rem 6rem 2rem 4rem;
  /* max-height: calc(100% - 7.5rem);
  overflow-y: scroll; */
}

.secondary__footer {
  display: none;
}

/* ==================== MEDIA QUERIES (SMALL MEDIUM DEVICES) ==================== */
@media screen and (max-width: 1024px) {
  .hero__container {
    grid-template-columns: 1fr;
    /* gap: 2rem; */
  }

  .promo__content {
    overflow: visible;
  }

  .service__content {
    margin: 3rem 4rem;
    height: auto;
    overflow: visible;
  }

  .secondary__footer {
    display: flex;
  }
}

/* ==================== MEDIA QUERIES (SMALL MEDIUM DEVICES) ==================== */
@media screen and (max-width: 666px) {
  .service__content {
    margin: 3rem 2rem;
  }
}
input[type="radio"], input[type="checkbox"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  appearance: none;
  /* For iOS < 15 to remove gradient background */
  background-color: #fff;
  /* Not removed via appearance */
  margin: 0;
}

.form__container {
  margin: 0 10rem;
  padding: 0;
  min-height: fit-content;
  max-height: 100%;
  transition: all ease 300ms;
}

.form__grid {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 4rem;
  align-items:flex-start;
  margin-bottom: 4rem;
}

.form__col {
  /* background-color: #ffbb5a; */
  position: relative;
}
/* 
.form__title {
  padding-top: 42%;
} */

.form__content {
  padding: 4rem 0;
  margin: 4rem 0;
  height: fit-content;
}

/* .form__call_msg {
  font-weight: 600;
} */

.form__call_msg, .form__usp {
  margin-bottom: 1.5rem;
}

.form__usp h4 {
  color: var(--knight-grey);
  margin-top: 1.25rem;
  font-size: 1rem;
}

.form__title {
  margin-bottom: 0rem;
}

.form__title h1 {
  display: flex;
  font-size: 4rem;
  line-height: 4rem;
  color: var(--mad-red);
}

.form__phone a, .form__mail a {
  font-size: 0.875rem;
}

.form__form {
  padding: 4rem 0;
  margin: 4rem 0;
}

.form__form_heading {
  margin-bottom: 1rem;
}

.form__form_grid {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1.5rem;
}

.form__form input {
  width: 100%;
  height: 2.25rem;
  margin-bottom: 1rem;
  padding: 0 4px;
  background: #fff;
  border-bottom: 2px solid var(--knight-grey);
  color: var(--knight-grey);
  font-family: var(--ffc);
  transition: var(--transition);
}

.form__form textarea {
  width: 100%;
  min-height: 10rem;
  margin-bottom: 1.5rem;
  border: 2px solid var(--knight-grey);
  padding: .75rem 1rem;
  background: #fff;
  color: var(--knight-grey);
  resize: none;
  font-family: var(--ffc);
  transition: var(--transition);
}

.form__form input:hover {
  border-bottom: var(--mad-red) solid 2px;
}

.form__form textarea:hover {
  border: var(--mad-red) solid 2px;
}

.form__form input:focus {
  border-bottom: var(--mad-red) solid 2px;
  /* color: var(--mad-red); */
  font-weight: 600;
}

.form__form textarea:focus {
  border: var(--mad-red) solid 2px;
  /* color: var(--mad-red); */
  font-weight: 600;
}

input[type="radio"] {
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  width: 1.25rem;
  height: 1.25rem;
  border: 0.125rem solid var(--knight-grey);
  border-radius: 50%;
  transform: translateY(-0.125rem);

  display: grid;
  place-content: center;
  align-self: center;
  transition: var(--transition);
}

input[type="radio"]::before {
  content: "";
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 50%;
  transform: scale(0);
  transition: 200ms transform ease-in-out;
  box-shadow: inset 1rem 1rem var(--mad-red);
}

input[type="checkbox"] {
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  width: 1.25rem;
  height: 1.25rem;
  border: 0.125rem solid var(--knight-grey);
  transform: translateY(-0.125rem);

  display: grid;
  place-content: center;
  align-self: center;
  transition: var(--transition);
}

input[type="checkbox"]::before {
  content: "";
  width: 0.625rem;
  height: 0.625rem;
  transform: scale(0);
  transition: 200ms transform ease-in-out;
  box-shadow: inset 1rem 1rem var(--mad-red);
}

input[type="radio"]:checked::before, input[type="checkbox"]:checked::before {
  transform: scale(1);
}

.req__container {
  margin: 2rem 0rem;
}

.req__list {
  display: flex;
  align-items:flex-start;
  margin-bottom: 1rem;
}

.req__item {
  display: flex;
  column-gap: .5rem;
  margin-right: 2rem;
  vertical-align: middle;
}

.req__item label {
  font-size: 1rem;
  align-self: center;
}

.misc__container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.form__form_terms {
  color: var(--knight-grey);
  font-size: 0.75rem;
  margin-bottom: 1rem;
  display: grid;
  grid-template-columns: 2rem auto;
  align-items: center;
}

.req__item:hover > input[type="radio"], .form__form_terms:hover > input[type="checkbox"] {
  border: 0.125rem solid var(--mad-red);
}

.req__item:focus > input[type="radio"], .form__form_terms:focus > input[type="checkbox"] {
  border: 0.125rem solid var(--mad-red);
}

.hyperlink {
  text-decoration: underline;
  color: var(--knight-grey);
  transition: var(--transition);
}

.hyperlink:visited {
  color: var(--moody);
}

.hyperlink:hover{
  color: var(--mad-red);
}

.form__form_message {
  color: var(--knight-grey);
  font-size: 0.75rem;
  margin-bottom: 1rem;
  text-align: right;
}

.form__form_message span {
  color: var(--moody);
  font-size: 1.25rem;
}

.form__form input[type="submit"] {
  width: auto;
  height: 3rem;
  background: var(--mad-red);
  color: #fff;
  transition: all ease 300ms;
  padding: 0 3rem;
  font-size: 1rem;
  border: 0px;
  outline: none;
  transition: var(--transition);
}

.form__form input[type="submit"]:hover {
  background-color: #fff;
  color: var(--mad-red);
  outline: 1px solid var(--mad-red);
}

.form__form input[type="submit"]:disabled {
  background-color: var(--light-grey);
  color: #fff;
}

.form__form input[type="submit"]:disabled:hover {
  outline: 0px solid var(--mad-red);
}

.form__form_submit {
  float: left;
}

/* media query for medium size devices */
@media screen and (max-width: 1024px) {
  .form__container {
    height: auto;
    margin: 0 4rem;
  }

  .form__grid {
    grid-template-columns: 1fr;
    margin-bottom: 0;
  }

  .form__form {
    position: relative;
    top: 0;
    transform: translateY(0);
    padding-bottom: 4rem;
    padding-top: 0;
  }

  .form__content {
    padding: 4rem 0 0 0;
    margin: 4rem 0 0 0;
    height: fit-content;
  }

  .form__form_heading h3 {
    line-height: 2rem;
  }
}

/* media query for small size devices */
@media screen and (max-width: 666px) {
  .form__container {
    height: auto;
    margin: 0 2rem;
  }

  .form__form_grid {
    grid-template-columns: 1fr;
  }

  .req__list {
    flex-wrap: wrap;
    row-gap: 0.75rem;
  }
  
  .misc__container {
    grid-template-columns: 1fr;
    width: auto;
  }

  .form__form_message {
    text-align: left;
  }

  .form__form_submit {
    width: 100%;
  }

  .form__form_submit input[type="submit"] {
    width: 100%;
  }
}
/* .recents__container {
  padding: 0.25rem;
} */

.recents__header {
  margin: 2rem 0;
  color: #ffffff;
}

.recents__list {
  background-color: #ffffff;
  padding: 0.5rem;
  margin: 0.5rem 0;
}

.recents__list a span, .recents__list > Link > span {
  font-size: 1.5rem;
  font-weight: 200;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.recent__card {
  /* cursor: pointer; */
  overflow: hidden;
  align-items: center;
  justify-content: center;
}

.recent__card img {
  width: 100%;
  height: 18rem;
  object-fit: cover;
  filter: gray; /* IE6-9 */
  -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
  filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
  opacity: 0.75;
  transition: 400ms opacity ease-in-out;
}

.recent__card:hover > img {
  -webkit-filter: grayscale(0);
  filter: none;
  opacity: 1;
}

/* ==================== MEDIA QUERIES (MEDIUM DEVICES) ==================== */
@media screen and (max-width: 1024px) {
  .recents__header {
    color: var(--knight-grey);
  }

  .recents__list {
    padding: 0;
    margin: 0;
  }

  .recent__card {
    margin-top: 0.5rem;
  }
}

/* ==================== MEDIA QUERIES (SMALL DEVICES) ==================== */
@media screen and (max-width: 666px) {
  .recent__card img {
    height: 16rem;
  }

  .recents__header h2 {
    font-size: 1.5rem;
    line-height: 1.75rem;
    margin-bottom: 0.75rem;
  }

  .recents__header p {
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.375rem;
  }
}
.btn {
  position: absolute;
  top: 40px;
  right: 40px;
  z-index: 2;
  display: flex;
  width: 48px;
  height: 48px;
  background: transparent;
  border-radius: 50%;
  transition: var(--transition);
}

.btn:hover {
  background: var(--mad-red);
}

.btn span {
  width: 16px;
  height: 2px;
  background: var(--knight-grey);
  display: flex;
  position: absolute;
  transition: var(--transition);
  transform: translateX(100%);
}

.btn:hover span {
  background: #fff;
}

.btn span:nth-child(1) {
  top: 40%;
}

.btn span:nth-child(2) {
  top: 60%;
}

.btn.is-active span:nth-child(1) {
  top: 50%;
  transform: translateX(100%) rotate(-135deg);
}

.btn.is-active span:nth-child(2) {
  top: 50%;
  transform: translateX(100%) rotate(135deg);
}
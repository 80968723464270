.footer__container {
  position: relative;
  width: 100vw;
  height: auto;
  /* position: absolute;
  left: 0;
  bottom: 0;
  display: block; */
  background: var(--light-grey);
}

.footer__content {
  margin: 4rem 10rem;
}

.footer__head {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 4rem 0;
  align-items: center;
}

/* .footer__title h1 {
  color: var(--mad-red);
} */

.footer__menu {
  opacity: 1;
  visibility: visible;
}

.footer__menu__list {
  display: block;
  flex-direction: column;
  list-style-type: none;
  transition: all 0.2s ease-in;
  text-align: right;
}

.footer__menu__item {
  margin: 0.75rem 0;
}

.footer__menu__item span {
  font-weight: 200;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.footer__menu__link {
  position: relative;
  cursor: pointer;
  font-size: 1.25rem;
  white-space: nowrap;
  color: var(--knight-grey);
  /* opacity: 0.40; */
  text-decoration: none;
  transition: var(--transition);
}

.footer__menu__link:hover {
  /* opacity: 1; */
  color: var(--mad-red);
}

.footer__menu__link:after {
  content: '';
  position: absolute;
  bottom: -4px;
  right: 0;
  width: 0;
  height: 2px;
  display: inline-flex;
  background: var(--mad-red);
  /* opacity: 0.40; */
  transition: var(--transition);
}

.footer__menu__link:hover:after {
  width: 100%;
  /* opacity: 1; */
}

.cta__wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 8rem;
}

.promo {
  display: flex;
  flex-direction: row;
  column-gap: 2rem;
  align-items: center;
}

.promo .tag__line {
  max-width: 20rem;
  margin-top: -0.75rem;
}

.promo .tag__line p {
  font-size: 1.25rem;
  color: var(--knight-grey);
}

.promo .tag__line p span {
  font-weight: 700;
  color: var(--mad-red);
}

.logo img {
  width: 8rem;
  height: auto;
}

.cta__option {
  /* margin-top: -1rem; */
}

/* ==================== MEDIA QUERIES (MEDIUM SIZE DEVICES) ==================== */
@media screen and (max-width: 1024px) {
  .footer__content {
    margin: 4rem;
  }

  .footer__menu {
    opacity: 0;
    visibility: hidden;
    display: none;
  }

  .cta__wrapper {
    flex-direction: column;
    margin-bottom: 6rem;
  }

  .promo {
    margin-bottom: 2rem;
  }
}

/* ==================== MEDIA QUERIES (SMALL SIZE DEVICES) ==================== */
@media screen and (max-width: 666px) {
  .footer__content {
    margin: 2rem;
  }

  .footer__head {
    flex-wrap: nowrap;
    margin: 2rem 0;
  }

  .logo img {
    width: 5rem;
    height: auto;
  }

  .cta__wrapper {
    margin-bottom: 6rem;
  }

  .promo {
    column-gap: 1rem;
    margin-bottom: 2rem;
  }

  .promo .tag__line p {
    font-size: 1rem;
  }

  .socials__footer {
    display: block;
    opacity: 1;
    visibility: visible;
  }
}
/* ================== GENERAL STYLES =================== */
.nav__container {
  background: var(--mad-red);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;

  clip-path: circle(20px at calc(100% - 65px) 65px);
  transition: var(--transition);

  opacity: 0;
  visibility: hidden;
}

.nav__container.is-active {
  opacity: 1;
  visibility: visible;

  clip-path: circle(75%);
}

.menu {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: var(--transition);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.menu__list {
  display: block;
  flex-direction: column;
  list-style-type: none;
  transition: all 0.2s ease-in;
}

.menu__item {
  overflow: hidden;
  transition: transform 1s cubic-bezier(0.4, 0, 0.2, 1);
  transform: translateY(-50%);
}

.nav__container.is-active .menu__item {
  transform: translateY(0);
}

.menu__item:not(:last-child) {
  margin-bottom: 1.5rem;
}

.menu__item:nth-child(4) {
  transition-delay: 0.8s;
}

.menu__item:nth-child(3) {
  transition-delay: 0.6s;
}

.menu__item:nth-child(2) {
  transition-delay: 0.4s;
}

.menu__item:nth-child(1) {
  transition-delay: 0.2s;
}

.menu__link {
  position: relative;
  display: inline-block;
  cursor: pointer;
  font-weight: bold;
  font-size: 4rem;
  white-space: nowrap;
  color: #fff;
  opacity: 0.40;
  text-decoration: none;
  transition: var(--transition);
  /* text-transform: uppercase; */
}

.menu__link:hover {
  color: #fff;
  opacity: 1;
  font-size: 8rem;
}

.nav__socials {
  color: var(--moody);
  /* font-size: 16px; */
  position: absolute;
  right: 40px;
  bottom: 36px;
  opacity: 1;
  visibility: visible;
}

.social__list {
  display: inline;
  list-style-type: none;
  flex-direction: row;
}

.social__item {
  display: inline;
}

.social__item:not(:last-child) {
  margin-right: 2rem;
}

.social__link {
  color: var(--moody);
  text-decoration: none;
  transition: var(--transition);
}

.social__link:hover {
  color: #fff;
}

.social__link:after {
  content: '';
  width: 0;
  height: 2px;
  display: inline-flex;
  background: var(--moody);
  transition: var(--transition);
}

.social__link:hover:after {
  background: #fff;
  width: 32px;
}

.trade-mark {
  color: var(--moody);
  font-size: 12px;
  position: absolute;
  left: 40px;
  bottom: 36px;
  cursor: default;
}

/* ==================== MEDIA QUERIES (SMALL SIZE DEVICES) ==================== */
@media screen and (max-width: 666px) {

  .menu__item:not(:last-child) {
    margin-bottom: 1rem;
  }

  .menu__link {
    font-size: 3rem;
  }

  .menu__link:hover {
    font-size: 5rem;
  }

  .nav__socials {
    opacity: 0;
    visibility: hidden;
  }
}
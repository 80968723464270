.contact__container {
  width: 100%;
  height: auto;
}

.contact__header {
  margin: 4rem 10rem;
}

.contact__header h1 {
  display: flex;
  font-size: 4rem;
  line-height: 4rem;
  color: var(--knight-grey);
}

.contact__header h1 br {
  display: none;
}

.contact__header p {
  font-size: 1.25rem;
}

.bottom__footer {
  display: flex;
}

/* ==================== MEDIA QUERIES (MEDIUM SIZE DEVICES) ==================== */
@media screen and (max-width: 1024px) {
  .contact__header {
    margin: 4rem;
  }

  .contact__header h1 {
    margin-bottom: 0.75rem;
  }

  .contact__header h1 br {
    display: flex;
  }

  .contact__header p {
    max-width: 30rem;
  }
}

/* ==================== MEDIA QUERIES (SMALL SIZE DEVICES) ==================== */
@media screen and (max-width: 666px) {
  .contact__header {
    margin: 2rem;
  }

  .contact__header h1 {
    font-size: 2.5rem;
    line-height: 2.75rem;
    margin-bottom: 0.5rem;
  }

  .contact__header p {
    font-size: 0.9rem;
  }
}
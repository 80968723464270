@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    /* list-style: none; */
    text-decoration: none;
}

:root {
  --mad-red: #cc0623;
  --knight-grey: #333333;
  --moody: #bbbbbb;
  --light-grey: #d9d9d9;

  --ffh: 'Bebas Neue', cursive;
  --ffc: 'Open Sans', sans-serif;

  --transition: all 400ms ease-in-out;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

body {
  background: #fff;
  color: var(--knight-grey);
  line-height: 1.7;
  font-family: var(--ffc);
  font-size: 18px;
}

h1 {
  font-family: var(--ffh);
  color: #fff;
  font-size: 6rem;
  font-weight: 400;
  line-height: 6rem;
}

h2 {
  font-family: var(--ffh);
  font-size: 2rem;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 2.5rem;
  margin-bottom: 1rem;
}

h3 {
  font-family: var(--ffh);
  font-size: 1.75rem;
  font-weight: 400;
  letter-spacing: 1px;
}

h4 {
  font-family: var(--ffc);
  color: #fff;
  font-size: 1.4rem;
  font-weight: 100;
  font-style: italic;
  line-height: 2rem;
  margin: 2rem 3rem 0 0;
}

.container {
  width: 100vw;
  height: 100vh;
  position: relative;
}

.nav__button {
  position: fixed;
  z-index: 11;
  top: 0px;
  right: 20px;
}

.btn {
  display: flex;
  width: 48px;
  height: 48px;
  background: transparent;
  border-radius: 50%;
  transition: var(--transition);
}

.btn:hover {
  background: var(--mad-red);
}

.btn span {
  width: 16px;
  height: 2px;
  background: var(--knight-grey);
  display: flex;
  position: absolute;
  transition: var(--transition);
  transform: translateX(100%);
}

.btn:hover span {
  background: #fff;
}

.btn span:nth-child(1) {
  top: 40%;
}

.btn span:nth-child(2) {
  top: 60%;
}

.btn.is-active span:nth-child(1) {
  top: 50%;
  transform: translateX(100%) rotate(-135deg);
}

.btn.is-active span:nth-child(2) {
  top: 50%;
  transform: translateX(100%) rotate(135deg);
}

/* ================== GENERAL STYLES =================== */
.container {
  width: 100vw;
  height: 100vh;
  position: relative;
}

/* ==================== MEDIA QUERIES (SMALL SIZE DEVICES) ==================== */
@media screen and (max-width: 666px) {
  h1 {
    font-size: 3.5rem;
    line-height: 4.5rem;
  }

  h4 {
    font-size: 1rem;
    line-height: 1.5rem;
    margin: 1.25rem 1rem 0 0;
  }

  .hero__container {
    grid-template-columns: 1fr;
    /* gap: 2rem; */
  }

  .nav__button {
    top: -20px;
    right: -20px;
  }
}
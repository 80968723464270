/* layout */
.grid__section {
  margin: 4rem 10rem;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
  grid-template-rows: masonry;
  grid-gap: 1rem;
  grid-auto-flow: dense;
  transition: all ease-in-out 400ms;
}

/* styling */
.card {
  cursor: pointer;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card__expanded {
  grid-column: span 2;
  /* grid-row: span 2; */
}

.card__img {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform ease-in-out 400ms;
}
/* 
.card__img:hover {
  transform: scale(1.03);
} */

/* ==================== MEDIA QUERIES (MEDIUM SIZE DEVICES) ==================== */
@media screen and (max-width: 1024px) {
  .grid__section {
    margin: 4rem;
  }
}

/* ==================== MEDIA QUERIES (SMALL SIZE DEVICES) ==================== */
@media screen and (max-width: 666px) {
  .grid__section {
    margin: 2rem;
  }
}
.about__section {
  width: 100%;
  height: auto;
}

.about__header {
  margin: 4rem 10rem;
}

.about__header h1, .sticky h1 {
  display: flex;
  font-size: 4rem;
  line-height: 4rem;
  color: var(--knight-grey);
  /* text-wrap: wrap; */
}

.about__header h1 br, .sticky h1 br {
  display: none;
}

.about__header p, .sticky p {
  font-size: 1.25rem;
}

.stickey_header {
  margin-top: 2rem;
  margin-left: 10rem;
}

.sticky_parent {
  height: 240vh;
}

.sticky {
  position: sticky;
  overflow: hidden;
  top: 0;
  height: 100vh;
  /* border: 1px solid red; */
}

.scroll_section {
  position: absolute;
  top: 0;
  height: 100%;
  width: 240vw;
  will-change: transform;
  display: flex;
  align-items:flex-end;
  padding: 0 5vw;
}

.scroll_section > img {
  height: calc(100% - 10rem);
  width: auto;
  object-fit: cover;
  object-position: center;
}

.about__content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 4rem;
  margin: 4rem 0;
}

.about__img {
  width: 100%;
  height: 100%;

}

.about__img img {
  height: 100%;
  width: 100%;
  /* max-width: 100%; */
  padding: 0.3rem 0;
  object-fit: cover;
}

.about__text p {
  margin-bottom: 1rem;
  text-align: justify;
}

.about__text p span {
  font-weight: 600;
  color: var(--mad-red);
}

/* ==================== MEDIA QUERIES (MEDIUM SIZE DEVICES) ==================== */
@media screen and (max-width: 1024px) {
  .about__header {
    margin: 4rem;
    margin-bottom: 0;
  }

  .stickey_header {
    margin: 4rem;
  }

  .about__header h1, .sticky h1 {
    margin-bottom: 0.75rem;
  }

  .about__header h1 br, .sticky h1 br {
    display: flex;
  }

  /* .about__header p, .sticky p {
    max-width: 30rem;
  } */

  .about__content {
    grid-template-columns: 1fr;
    row-gap: 2rem;
    column-gap: 0rem;
    margin-bottom: 0;
  }

  .about__img{
    display: flex;
    justify-content: center;
    transition: var(--transition);
  }

  .about__img a img{
    margin-bottom: 2rem;
  }

  .about__text p {
    max-width: 100%;
  }

  .sticky_parent {
    height: 500vh;
  }

  .scroll_section {
    width: 500vw;
  }

  .scroll_section > img {
    height: calc(100% - 16rem);
  }
}

/* ==================== MEDIA QUERIES (SMALL SIZE DEVICES) ==================== */
@media screen and (max-width: 666px) {
  .about__header {
    margin: 2rem;
    margin-bottom: 0;
  }

  .stickey_header {
    margin: 2rem;
  }

  .about__header h1, .sticky h1 {
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 0.5rem;
  }

  .about__header p, .sticky p {
    font-size: 0.9rem;
  }

  .about__content {
    margin: 2rem 0 0 0;
  }

  .sticky_parent {
    height: 700vh;
  }

  .scroll_section {
    width: 700vw;
  }

  .scroll_section > img {
    height: calc(100% - 12rem);
  }
}
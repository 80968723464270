.work__container {
  display: block;
  width: 100%;
  height: auto;
  overflow-wrap: normal;
  padding: 4rem 10rem;
}

.work__header {
  margin-bottom: 4rem;
}

.work__header h1 {
  display: flex;
  font-size: 4rem;
  line-height: 4rem;
  color: var(--knight-grey);
}

.work__header h1 br {
  display: none;
}

.work__header p {
  font-size: 1.25rem;
}

.bottom__footer {
  display: flex;
}

/* .grid__section {
  margin: 4rem 10rem;
} */

.works__grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
  grid-template-rows: masonry;
  grid-gap: 1rem;
  grid-auto-flow: dense;
  transition: all ease-in-out 400ms;
  /* margin: 4rem 10rem; */
  width: 100%;
}

/* styling */
.work__card {
  cursor: pointer;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: flex-end;
  margin: 0.5rem;
}

.work__card::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, #fff, #ffffff00);
  z-index: 2;
  transition: var(--transition);
  opacity: 0;
}

.work__card:hover::before {
  opacity: 1;
}

.work__info {
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 3;
  /* padding: 1rem 1.5rem; */
  align-items: first baseline;
  justify-content: space-between;
  transition: var(--transition);
  opacity: 0;
  display: none;
}

.work__info_caption {
  display: flex;
  flex-direction: row;
  /* align-content: space-between; */
  padding: 0 0.5rem;
  justify-content: space-between;
  width: 100%;
}

.work__info_caption h1 {
  font-size: 5rem;
  color: var(--knight-grey);
  /* letter-spacing: auto; */
}

/* .work__info a span, .work__info > Link > span {
  font-size: 1.5rem;
  font-weight: 200;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
} */

.work__card img {
  top: 0;
  left: 0;
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform ease-in-out 400ms;
  z-index: 0;
}

.work__card:hover > img, .work__card:focus > img {
  transform: scale(1.125);
}

.work__card:hover > .work__info {
  opacity: 1;
  display: block;
}

/* ==================== MEDIA QUERIES (MEDIUM SIZE DEVICES) ==================== */
@media screen and (max-width: 1024px) {
  .work__container {
    padding: 4rem;
  }
  
  .work__header h1 {
    margin-bottom: 0.75rem;
  }

  .work__header h1 br {
    display: flex;
  }

  .work__header p {
    max-width: 30rem;
  }
}

/* ==================== MEDIA QUERIES (SMALL SIZE DEVICES) ==================== */
@media screen and (max-width: 666px) {
  .work__container {
    padding: 2rem;
  }
  
  .work__header {
    margin-bottom: 2rem;
  }

  .work__header h1 {
    font-size: 2.5rem;
    line-height: 2.75rem;
    margin-bottom: 0.5rem;
  }

  .work__header p {
    font-size: 0.9rem;
  }

  .work__info_caption h1 {
    font-size: 4rem;
  }
}
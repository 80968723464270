.cta {
  display: flex;
  flex-direction: row;
  column-gap: 1.5rem;
}

.cta_btn_container {
  /* background: var(--knight-grey); */
  border: 3px solid var(--knight-grey);
  /* border-radius: 10px; */
  padding: 0rem 1.5rem;
  text-align: center;
  transition: var(--transition);
}

.cta__btn {
  width: max-content;
  /* color: #fff; */
  color: var(--knight-grey);
  /* opacity: 0.40; */
  cursor: pointer;
  transition: var(--transition);
  font-family: var(--ffh);
  font-size: 2rem;
  font-weight: 400;
  text-decoration: underline;
  display: inline-block;
  vertical-align: top;
}

.cta__btn > span {
  font-size: 2.25rem;
  font-weight: 200;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.cta__btn:hover{
  /* opacity: 1; */
  color: var(--mad-red);
}

.cta_btn_container:hover {
  border-color: transparent;
  background-color: #fff;
}

.usp__container h4 {
  color: var(--knight-grey);
  /* opacity: 0.40; */
  font-family: var(--ffc);
  font-size: 1.5rem;
  font-weight: 400;
  margin-top: 0rem;
  /* line-height: 1.5rem; */
  font-style: normal;
  display: inline-block;
  vertical-align: top;
}

/* ==================== MEDIA QUERIES (SMALL SIZE DEVICES) ==================== */
@media screen and (max-width: 666px) {
  .cta {
    flex-direction: column;
    row-gap: 0.5rem;
  }

  /* .cta__btn {
    font-size: 3.25rem;
  } */

  .cta h4 {
    font-size: 1.25rem;
    padding-left: 0.25rem;
  }
}
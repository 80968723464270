.socials {
  color: var(--moody);
  font-size: 0.75rem;
  /* position: inherit; */
  position: absolute;
  bottom: 2rem;
  right: 5rem;
  opacity: 1;
  visibility: visible;
  z-index: 8;
}

.social__list {
  display: inline;
  list-style-type: none;
  flex-direction: row;
}

.social__item {
  display: inline;
}

.social__item span {
  font-weight: 200;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.social__item:not(:last-child) {
  margin-right: 2rem;
}

.social__link {
  color: var(--knight-grey);
  text-decoration: none;
  transition: var(--transition);
}

.social__link:hover {
  color: var(--mad-red);
}

.social__link:after {
  content: '';
  width: 0;
  height: 0.1rem;
  display: inline-flex;
  background: var(--knight-grey);
  transition: var(--transition);
}

.social__link:hover:after {
  background: var(--mad-red);
  width: 1rem;
}

.trade-mark {
  color: var(--knight-grey);
  font-size: 0.75rem;
  position: absolute;
  left: 6rem;
  bottom: 2rem;
  cursor: default;
}

/* ==================== MEDIA QUERIES (MEDIUM SIZE DEVICES) ==================== */
@media screen and (max-width: 1024px) {
  .trade-mark {
    left: 4rem;
  }
}

/* ==================== MEDIA QUERIES (SMALL SIZE DEVICES) ==================== */
@media screen and (max-width: 666px) {
  .socials {
    right: 2rem;
  }

  .social__list {
    display: flex;
    flex-direction: column;
    text-align: right;
  }

  .social__item:not(:last-child) {
    margin-right: 0rem;
    margin-bottom: 0.5rem;
  }

  .trade-mark {
    left: 2rem;
  }
}
.staller__box {
  height: 25vh;
}

.services__container {
  color: #fff;
  z-index: 0;
}

.services__container p {
  display: inline-block;
  font-family: var(--ffc);
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.375rem;
  margin-bottom: 2.5rem;
}

.services__content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
}

.service__card {
  margin-left: 2rem;
}

.services__list {
  list-style:disc;
  margin-bottom: 1.75rem;
}

.service__item {
  font-size: 0.85rem;
  line-height: 1.75rem;
  margin-left: 1.5rem;
  font-weight: 600;
}

.service__desc p {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.75rem;
  margin-top: 3rem;
  color: #fff;
  opacity: 0.6;
}

/* ==================== MEDIA QUERIES (MEDIUM DEVICES) ==================== */
@media screen and (max-width: 1024px) {
  .staller__box {
    /* height: 4rem; */
    display: none;
  }

  .services__container {
    color: var(--knight-grey);
  }

  .service__desc p {
    color: var(--moody);
    opacity: 1;
  }
}

/* ==================== MEDIA QUERIES (SMALL DEVICES) ==================== */
@media screen and (max-width: 666px) {
  .services__container {
    margin: 0;
  }
  
  .services__container h2 {
    font-size: 1.5rem;
  }

  .services__container p {
    margin-bottom: 2rem;
  }

  .services__container p br {
    display: none;
  }

  .services__content {
    grid-template-columns: 1fr;
    /* column-gap: 1.5rem; */
  }

  .service__card {
    margin-left: 0;
  }

  .service__card h3 {
    font-size: 1.5rem;
  }

  .service__desc p {
    margin-top: 0rem;
  }
}